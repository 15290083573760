@import url('./styles/index.css');
@import url('~slick-carousel/slick/slick.css');
@import url('~react-h5-audio-player/lib/styles.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  @apply text-rebecca;
}

.space-before::before,
.space-after::after,
.space-both:before,
.space-both:after {
  content: '\00a0';
}

.slick-slider {
  @apply w-full;
}

.required-label:after {
  content: '*';
  display: inline-block;
  font-size: smaller;
  @apply inline-block relative left-0.5 -top-0.5 text-error;
}

.react-flow__handle {
  opacity: 0;
}

.react-flow__panel.react-flow__attribution {
  font-size: 1px;
}

/**
 * Tailwindcss's `break-words` rule is not that expeted
 */
.word-break-case {
  word-break: break-word;
}

.canvasjs-chart-credit {
  display: none;
}
