.react-datepicker {
  border: 0;
  font-size: 14px;
  font-family: Inter;
}

.react-datepicker__current-month {
  font-weight: 400;
  font-size: 15px;
  color: #141619;
}

.react-datepicker__month-container + .react-datepicker__month-container {
  margin-left: 8px;
}

.react-datepicker__header {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.react-datepicker__day-names {
  margin: 8px 0;
}

.react-datepicker__day-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #71717b;
  width: 40px;
  height: 40px;
}

.react-datepicker__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  top: -3px;
  width: 24px;
  height: 24px;
}

.react-datepicker__navigation-icon::before {
  top: 9px;
  border-color: #141619 !important;
  border-width: 2px 2px 0 0;
  transition: all 0.3s ease;
}

.react-datepicker__navigation-icon--previous::before {
  right: -5px;
}

.react-datepicker__navigation-icon--next::before {
  left: -5px;
}

.react-datepicker__day {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 1px 0;
  width: 40px;
  height: 40px;
  color: #141619;
  border-radius: 0 !important;
  transition: all 0.3s ease;
}

.react-datepicker__day--outside-month {
  height: 0;
}

.react-datepicker__day:not([aria-disabled='true']):hover {
  background-color: #f0f0fc;
}

.react-datepicker__day--disabled {
  color: #bbbbc1;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #f0f0fc !important;
}

.react-datepicker__day--in-range:not([aria-disabled='true']):hover,
.react-datepicker__day--in-range:not(
    .react-datepicker__day--range-start
  ):hover {
  border-radius: 0;
  background-color: #f0f0fc;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  color: white;
  background-color: #5f5fc4 !important;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end {
  color: white;
  background-color: #8d8deb !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-end {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
